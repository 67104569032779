import './Task.css';

import { useState, useEffect } from 'react';
import axios from 'axios';
import TaskColumn from './TaskColumn';

function Task({
    data,
    setSelectedTask,
    headerItems,
}) {
    const [columns, setColumns] = useState([]);

    const loadColumns = () => {
        axios.get(
            `https://api2.cctask.com/app/task_columns/?task_id=${data.id}`
        ).then(
            (response) => {
                setColumns(response.data.results);
            }
        )
    }

    var divColumns = null;
    if (columns) {
        divColumns = headerItems.map(
            h =>
                <div>
                    <TaskColumn
                        value='empty'
                        header={h}
                        columns={columns}
                        task={data}
                        loadColumns={loadColumns}
                    />
                </div>
        );
    }

    useEffect(
        () => {
            loadColumns();
        },
        []
    );

    return (
        <div className="task">
            <div style={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }} >
                <div
                    className='task-title'
                    onClick={() => setSelectedTask(data)}
                >
                    {data?.title}
                </div>
                {divColumns}
                {/* <div>
                    <TaskColumn
                        value='Done'
                        bgColor='#ff3e3e'
                        color='white'
                    />
                </div>
                <div>
                    <TaskColumn
                        value='John Doe'
                        bgColor='orange'
                        color='white'
                    />
                </div>
                <div>
                    <TaskColumn
                        value='UK'
                        bgColor='lightblue'
                        color='white'
                    />
                </div>
                <div>
                    <TaskColumn
                        value='2025'
                        bgColor='#3eff7e'
                        color='black'
                    />
                </div> */}
            </div>
        </div>
    );
}

export default Task;