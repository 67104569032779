import './TaskColumn.css';

import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';

function TaskColumn({
    value,
    bgColor,
    color,
    header,
    columns,
    task,
    loadColumns,
}) {
    const [showDialog, setShowDialog] = useState(false);
    const [newValue, setNewValue] = useState(value);
    const [suggestions, setSuggestions] = useState([]);

    var column = null;
    if (columns) {
        column = columns.filter(
            c => c.header_id == header.id
        );
        column = column.pop();
    }

    var divValue = value;
    if (column) {
        divValue = column.title;
    }

    const updateValue = (new_value, color, background_color) => {
        axios.post(
            `https://api2.cctask.com/app/task_columns/`,
            {
                title: new_value,
                task: `https://api2.cctask.com/app/tasks/${task.id}/`,
                header: `https://api2.cctask.com/app/header_items/${header.id}/`,
                group: `https://api2.cctask.com/app/groups/${task.group_id}/`,
                color: color,
                background_color: background_color,
            }
        ).then(
            (response) => {
                setShowDialog(false);
                loadColumns();
                loadSuggestions();
            }
        )
    };

    const loadSuggestions = () => {
        if (!task) {
            return;
        }
        axios.get(
            `https://api2.cctask.com/app/task_columns/?group_id=${task.group_id}&header_id=${header.id}`
        ).then(
            (response) => {
                const all_suggestions = response.data.results;
                var suggestions = [];
                var map = {};

                all_suggestions.forEach(
                    s => {
                        var key = s.title + s.color + s.background_color;
                        if (map[key]) {
                            return;
                        }
                        map[key] = true;
                        suggestions.push(s);
                    }
                )

                setSuggestions(suggestions);
            }
        );
    }

    const divSuggestions = suggestions.map(
        s =>
            <div
                style={{
                    margin: '0.4em',
                    padding: '1em',
                    cursor: 'pointer',
                    borderRadius: '0.4em',
                    backgroundColor: s.background_color,
                    color: s.color
                }}
                onClick={
                    () => {
                        updateValue(s.title, s.color, s.background_color);
                    }
                }
            >
                {s.title}
            </div>
    );
    const suggestionVariants = [
        {
            background_color: '#ff3e3e',
            color: 'white'
        },
        {
            background_color: 'green',
            color: 'white'
        },
        {
            background_color: 'orange',
            color: 'white'
        }
    ];
    const divSuggestionVariants = suggestionVariants.map(
        v =>
            <div
                style={{
                    margin: '0.4em',
                    padding: '1em',
                    cursor: 'pointer',
                    borderRadius: '0.4em',
                    backgroundColor: v.background_color,
                    color: v.color
                }}
                onClick={
                    () => {
                        updateValue(newValue, v.color, v.background_color);
                    }
                }
            >
                {newValue}
            </div>
    );

    useEffect(
        () => {
            loadSuggestions();
        },
        [value, header, task, column, columns]
    );

    return (
        <div>
            <div
                // align='center'
                onClick={
                    () => {
                        loadSuggestions();
                        setShowDialog(true);
                    }
                }
                className="task-column"
                style={{
                    backgroundColor: column?.background_color,
                    color: column?.color,
                    width: '6em',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {divValue}
            </div >
            <Dialog
                header="Value"
                visible={showDialog}
                // style={{ width: '10em' }}
                onHide={() => setShowDialog(false)}
                modal={false}
            >
                <div>
                    <div>
                        <h4>Suggestions:</h4>
                        {divSuggestions}
                    </div>
                    <div>
                        <h4>New value:</h4>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <InputText
                                    value={newValue}
                                    onChange={(e) => setNewValue(e.target.value)}
                                />
                            </div>
                            {divSuggestionVariants}
                        </div>
                        <div
                            style={{ margin: '1em' }}
                            align='center'
                        >
                            <Button
                                label="OK"
                                onClick={() => updateValue(newValue)}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default TaskColumn;