import axios from 'axios';
import Group from './Group';
import Header from './Header';
import TaskPage from './TaskPage';

import { useEffect, useState } from 'react';

import './TaskPage.css';
import GroupSettings from './GroupSettings';

function Home() {
	const [selectedTask, setSelectedTask] = useState(null);
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [headerItems, setHeaderItems] = useState([]);

	var divSelectedTask = null;
	if (selectedTask) {
		divSelectedTask =
			<div style={{ flex: '1' }}>
				<TaskPage
					selectedTask={selectedTask}
					setSelectedTask={setSelectedTask}
				/>
			</div>;
	}

	const createGroup = () => {
		axios.post(
			'https://api2.cctask.com/app/groups/',
			{
				title: 'New Group'
			}
		).then(
			(_response) => {
				loadGroups();
			}
		)
	};

	function loadHeaderItems(group_id) {
		return axios.get(
			`https://api2.cctask.com/app/header_items/?group_id=${group_id}`,
			{
			}
		)
	}

	function deleteHeaderItem(group_id, header_item_id) {
		return axios.delete(
			`https://api2.cctask.com/app/header_items/${header_item_id}/?group_id=${group_id}`,
		)
	}

	const loadGroups = () => {
		axios.get(
			'https://api2.cctask.com/app/groups/'
		).then(
			(response) => {
				setGroups(response.data.results);
			}
		)
	}

	const divGroupSettings =
		selectedGroup
			? <GroupSettings
				selectedGroup={selectedGroup}
				loadGroups={loadGroups}
				setSelectedGroup={setSelectedGroup}
				headerItems={headerItems}
				loadHeaderItems={loadHeaderItems}
				deleteHeaderItem={deleteHeaderItem}
			/>
			: null;

	const divGroups = groups.map(
		g =>
			<div>
				<Group
					data={g}
					selectedTask={selectedTask}
					setSelectedTask={setSelectedTask}
					loadGroups={loadGroups}
					setSelectedGroup={setSelectedGroup}
					loadHeaderItems={loadHeaderItems}
				/>
			</div>
	);

	useEffect(
		() => {
			loadGroups();
		},
		[]
	);

	useEffect(
		() => {
			if (selectedTask) {
				setSelectedGroup(false);
			}
		},
		[selectedTask]
	);

	return (
		<div>
			<Header />
			<div
				style={{ padding: '0.5em', color: 'white', cursor: 'pointer', width: '5em' }}
				onClick={createGroup}
			>
				New Group
			</div>
			<div style={{ display: 'flex', flexShrink: '0', flexGrow: '1' }}>
				<div style={{ flex: '1' }}>
					{divGroups}
				</div>
				{divGroupSettings}
				{divSelectedTask}
			</div>
		</div>
	);
}

export default Home;