import { Avatar } from 'primereact/avatar';
import './Comment.css';

function Comment({ data }) {
    return (
        <div className='comment'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    image="/images/avatar/amyelsner.png"
                    size="small"
                    shape="circle"
                />
                <div style={{ marginLeft: '0.4em' }}>
                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {data.author}
                    </div>
                    <div style={{ fontSize: '12px' }}>
                        8 days ago
                    </div>
                </div>
            </div>
            <div>
                {data.title}
            </div>
        </div>
    );
}

export default Comment;