import { useEffect, useState } from 'react';
import './Group.css';
import Task from './Task';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import TasksHeader from './TasksHeader';

function Group({
    data,
    selectedTask,
    setSelectedTask,
    loadGroups,
    setSelectedGroup,
    loadHeaderItems,
}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [newTaskTitle, setNewTaskTitle] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [groupTitle, setGroupTitle] = useState(data.title);
    const [headerItems, setHeaderItems] = useState([]);

    var divTasks =
        <div>
            {tasks.length} tasks
        </div>;

    const createTask = (title) => {
        axios.post(
            'https://api2.cctask.com/app/tasks/',
            {
                title: title,
                status: 1,
                group: `https://api2.cctask.com/app/groups/${data.id}/`
            }
        ).then(
            (_response) => {
                loadTasks();
            }
        )
    }

    if (isExpanded) {
        divTasks = [];

        divTasks.push(
            <TasksHeader
                data={data}
                setSelectedTask={setSelectedTask}
                headerItems={headerItems}
            />
        );

        divTasks.push(tasks.map(
            t =>
                <Task
                    data={t}
                    setSelectedTask={setSelectedTask}
                    headerItems={headerItems}
                />
        ));
        divTasks.push(
            <div>
                <input
                    type='text'
                    className='task-new'
                    placeholder='create a new task'
                    value={newTaskTitle}
                    onChange={
                        (e) => {

                            setNewTaskTitle(e.target.value);
                        }
                    }
                    onKeyDown={
                        (e) => {
                            if (e.key == 'Enter') {
                                createTask(newTaskTitle);
                                setNewTaskTitle('');
                                return;
                            }
                            if (e.key == 'Escape') {
                                setNewTaskTitle('');
                                return;
                            }
                        }
                    }
                />
            </div>
        );
    }

    var chevron = isExpanded
        ? <i className="pi pi-angle-right"
            style={{ fontSize: '2rem' }} />
        : <i className="pi pi-angle-down"
            style={{ fontSize: '2rem' }} />;

    const loadTasks = () => {
        axios.get(
            `https://api2.cctask.com/app/tasks/?group_id=${data.id}`,
            {
            }
        ).then(
            (response) => {
                setTasks(response.data.results);
            }
        )
    }

    const updateGroupTitle = (group_id, new_title) => {
        axios.patch(
            `https://api2.cctask.com/app/groups/${group_id}/`,
            {
                title: new_title
            }
        ).then(
            (_response) => {
                loadGroups();
            }
        )
    }

    const divTitle =
        <div>
            {
                isTitleEditable
                    ? <div>
                        <InputText
                            value={groupTitle}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                backgroundColor: '#252746',
                                color: 'white',
                                width: '100%',
                                marginTop: '0.5em',
                                marginBottom: '0.5em',
                            }}
                            onKeyDown={
                                (e) => {
                                    if (e.key == 'Enter') {
                                        updateGroupTitle(data.id, groupTitle);
                                        setIsTitleEditable(false);
                                    }
                                    if (e.key == 'Escape') {
                                        setGroupTitle(data.title);
                                        setIsTitleEditable(false);
                                    }
                                }
                            }
                            onChange={
                                (e) => {
                                    setGroupTitle(e.target.value);
                                }
                            }
                        />
                    </div>
                    : <div onClick={() => setIsTitleEditable(true)}>
                        <h2>
                            {data.title}
                        </h2>
                    </div>
            }
        </div>;

    const loadTest = () => {
        loadHeaderItems(data.id).then(
            (response) => {
                setHeaderItems(response.data.results);
            }
        );
    }

    useEffect(
        () => {
            loadTasks();
            loadTest();
        },
        [data]
    );

    return (
        <div>
            <div
                align='left'
                className='group'
            >
                <div className='group-left'>
                </div>
                <div className='group-body'>
                    <div className='group-title'>
                        <div
                            className='group-title-arrow'
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {chevron}
                        </div>
                        {divTitle}
                        {
                            isExpanded && !selectedTask
                                ? <div
                                    style={{
                                        marginLeft: 'auto',
                                        padding: '0.5em',
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        fontSize: '32',
                                    }}
                                    onClick={() => setSelectedGroup(data)}
                                >
                                    ...
                                    <Dialog
                                        header="Manage group"
                                        visible={showOptions}
                                        onHide={() => setShowOptions(false)}
                                    >
                                        <div style={{ width: '20em' }}>
                                            Hello
                                        </div>
                                    </Dialog>
                                </div>
                                : null
                        }
                    </div>
                    {divTasks}
                </div>
            </div>
        </div>
    );
}

export default Group;