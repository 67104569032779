import './Header.css';

function Header() {
    return (
        <div className='header'>
            <div style={{ display: 'flex' }}>
                <div className='header-item'>
                    Home
                </div>
                <div className='header-item'>
                    Contact
                </div>
                <div
                    className='header-item'
                    onClick={() => {
                        window.location = '/'
                    }}
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    Logout
                </div>
            </div>
        </div>
    );
}

export default Header;