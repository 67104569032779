import './LandingPage.css';
import axios from 'axios';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

function LandingPage() {

    const login = (google_response) => {
        console.log("calling the api...");
        axios.post(
            'https://api2.cctask.com/app/login/',
            {
                token: google_response.credential,
                username: 'user123',
                password: 'pass123'
            }
        ).then(
            (response) => {
                console.log('Login success from api');
                console.log(response.data);
                if (response.data.next) {
                    window.location = '/home';
                }
            }
        ).catch(
            (error) => {
                console.log("ERROR");
                console.log(error);
            }
        );
    }

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '5em' }}>
                    <h1 style={{ color: '#eb4034' }}>
                        cctask
                    </h1>
                    <h4 style={{ color: 'red' }}>
                        Simple tasks.
                    </h4>
                    <div style={{ color: 'white' }}>
                        We love gettings thigs done and deeply hate long-living-task-epics.
                    </div>
                </div>
                <div
                    align='center'
                    className='landing-login'
                >
                    <div style={{ color: 'white' }}>
                        <h2>
                            Signup or Login
                        </h2>
                    </div>
                    <GoogleOAuthProvider
                        clientId="143218772517-16fjtn6hirj7om91t6ce16n7n67csbco.apps.googleusercontent.com"
                    >
                        <GoogleLogin
                            onSuccess={
                                (response) => {
                                    login(response);
                                }
                            }
                            onError={() => { console.log('err') }}
                            type='standard'
                            useOneTap={false}
                            text='continue_with'
                        />
                    </GoogleOAuthProvider>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;