import { useState } from 'react';
import './App.css';
import TaskPage from './TaskPage';
import Home from './Home';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';

function App() {
  const [selectedTask, setSelectedTask] = useState(null);

  var divSelectedTask = null;
  if (selectedTask) {
    divSelectedTask =
      <div style={{ flex: '1' }}>
        <TaskPage
          data={selectedTask}
          setSelectedTask={setSelectedTask}
        />
      </div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/home' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
