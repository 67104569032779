import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { useEffect, useState } from 'react';

function GroupSettings({
    selectedGroup,
    setSelectedGroup,
    loadGroups,
    loadHeaderItems,
    deleteHeaderItem,
}) {
    const [newHeaderItemTitle, setNewHeaderItemTitle] = useState(null);
    const [headerItems, setHeaderItems] = useState([]);

    const deleteGroup = (group_id) => {
        axios.delete(
            `https://api2.cctask.com/app/groups/${group_id}/`
        ).then(
            (_response) => {
                setSelectedGroup(null);
                loadGroups();
            }
        )
    }

    const createHeaderItem = () => {
        axios.post(
            'https://api2.cctask.com/app/header_items/',
            {
                title: newHeaderItemTitle,
                group: `https://api2.cctask.com/app/groups/${selectedGroup.id}/`
            }
        ).then(
            (_response) => {
                loadHeaderItems(selectedGroup.id).then(
                    (response) => {
                        setHeaderItems(response.data.results);
                        loadGroups();
                    }
                );
            }
        )
    };

    const divHeaderItems = headerItems.map(
        h =>
            <div
                key={h.id}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div>
                    {h.title}
                </div>
                <div
                    style={{ padding: '1em', cursor: 'pointer' }}
                    onClick={() => {
                        deleteHeaderItem(selectedGroup.id, h.id).then(
                            (response) => {
                                loadHeaderItems(selectedGroup.id).then(
                                    (response) => {
                                        setHeaderItems(response.data.results);
                                        loadGroups();
                                    }
                                );
                            }
                        )
                    }}
                >
                    Delete
                </div>
            </div>
    );

    useEffect(
        () => {
            loadHeaderItems(selectedGroup.id).then(
                (response) => setHeaderItems(response.data.results)
            );
        },
        []
    );

    return (
        <div className='task-page' style={{ flex: '1' }}>
            <div
                align='right'
                onClick={() => setSelectedGroup(null)}
                style={{ cursor: 'pointer' }}
            >
                <i className="pi pi-times"
                    style={{ fontSize: '1rem' }} />
            </div>
            <h2>{selectedGroup.title}</h2>
            <h4>
                Columns
            </h4>
            {divHeaderItems}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputText
                    placeholder='new column'
                    value={newHeaderItemTitle}
                    onChange={(e) => setNewHeaderItemTitle(e.target.value)}
                />
                <div style={{ padding: '1em' }}>
                    <Button
                        label="Create"
                        onClick={createHeaderItem}
                    />
                </div>
            </div>
            <h4>
                Delete
            </h4>
            <div>
                Delete this group and all its tasks:
            </div>
            <div style={{ marginTop: '0.5em' }}>
                <Button
                    label="Delete"
                    severity="danger"
                    raised
                    onClick={() => deleteGroup(selectedGroup.id)}
                />
            </div>
        </div>
    );
}

export default GroupSettings;