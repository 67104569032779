import './Task.css';

import TaskColumn from './TaskColumn';

function TasksHeader({
    data,
    setSelectedTask,
    headerItems,
}) {
    const divHeaderItems = headerItems.map(
        h =>
            <div>
                <TaskColumn
                    value={h.title}
                    bgColor='none'
                    color='white'
                    header={h}
                    columns={null}
                />
            </div>
    );

    return (
        <div className="task">
            <div style={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }} >
                <div
                    className='task-title'
                    onClick={() => setSelectedTask(data)}
                >
                    {/* {data?.title} */}
                </div>
                {divHeaderItems}
            </div>
        </div>
    );
}

export default TasksHeader;