import './Group.css';
import './TaskPage.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { Button } from 'primereact/button';

import Comment from './Comment';

function TaskPage({ selectedTask, setSelectedTask }) {
    const [taskBody, setTaskBody] = useState(null);
    const [isBodyEditable, setIsBodyEditable] = useState(false);

    const [taskTitle, setTaskTitle] = useState(null);
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const [postTitle, setPostTitle] = useState('');

    const [posts, setPosts] = useState([]);
    const [isTimeCounterRunning, setIsTimeCounterRunning] = useState(false);

    const divPosts = posts.map(
        c =>
            <Comment data={c} />
    );

    const updateTaskBody = (task_id, new_body) => {
        axios.patch(
            `https://api2.cctask.com/app/tasks/${task_id}/?group_id=${selectedTask.group_id}`,
            {
                body: new_body ?? ' ',
            }
        ).then(
            (_response) => {
            }
        )
    }

    const updateTaskTitle = (task_id, new_title) => {
        axios.patch(
            `https://api2.cctask.com/app/tasks/${task_id}/?group_id=${selectedTask.group_id}`,
            {
                title: new_title,
            }
        ).then(
            (_response) => {
            }
        )
    }

    const divBody = <div>
        {isBodyEditable ?
            <div>
                <InputTextarea
                    placeholder='Add here some task details...'
                    value={taskBody}
                    style={{
                        backgroundColor: '#252746',
                        color: 'white',
                        width: '100%'
                    }}
                    autoResize={true}
                    onChange={(e) => setTaskBody(e.target.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'right' }} >
                    <div
                        align='right'
                        style={{
                            margin: '0.5em',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setTaskBody(selectedTask.body);
                            setIsBodyEditable(false);
                        }}
                    >
                        Cancel
                    </div>
                    <div
                        align='right'
                        style={{
                            margin: '0.5em',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            updateTaskBody(selectedTask.id, taskBody);
                            setIsBodyEditable(false);
                        }}
                    >
                        Save
                    </div>
                </div>
            </div>
            :
            <p
                style={{
                    whiteSpace: 'pre-wrap',
                    cursor: 'pointer',
                }}
                onClick={() => setIsBodyEditable(true)}
            >
                {taskBody ?? ' '}
            </p>
        }
    </div>;

    const divTitle =
        <div>
            {
                isTitleEditable
                    ? <InputText
                        value={taskTitle}
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            backgroundColor: '#252746',
                            color: 'white',
                            width: '100%',
                        }}
                        onChange={
                            (e) => {
                                setTaskTitle(e.target.value);
                            }
                        }
                        onKeyDown={
                            (e) => {
                                if (e.key == 'Enter') {
                                    updateTaskTitle(selectedTask.id, taskTitle);
                                    setIsTitleEditable(false);
                                }
                            }
                        }
                    />
                    : <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsTitleEditable(true)}
                    >
                        <h2>{taskTitle}</h2>
                    </div>
            }
        </div>;

    const createPost = () => {
        if (!postTitle || postTitle == '') {
            return;
        }

        axios.post(
            'https://api2.cctask.com/app/posts/',
            {
                title: postTitle,
                group: `https://api2.cctask.com/app/groups/${selectedTask.group_id}/`,
                task: `https://api2.cctask.com/app/tasks/${selectedTask.id}/`,
            }
        ).then(
            (response) => {
                setPostTitle('');
                loadPosts();
            }
        );
    }

    const loadPosts = () => {
        axios.get(
            `https://api2.cctask.com/app/posts/?task_id=${selectedTask.id}`
        ).then(
            (response) => {
                setPosts(response.data.results);
            }
        );
    }

    const divTimeCounter =
        <div>
            <h4>
                Time Management
            </h4>
            {
                isTimeCounterRunning &&
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <i
                        className="pi pi-spin pi-cog"
                        style={{ fontSize: '2rem' }}
                    />
                    <Button
                        label="Stop"
                        severity="danger"
                        onClick={() => setIsTimeCounterRunning(false)}
                        style={{ margin: '0.5em' }}
                    />
                </div>
            }
            {
                !isTimeCounterRunning &&
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <i
                        className="pi pi-cog"
                        style={{ fontSize: '2rem' }}
                    />
                    <Button
                        label="Start"
                        severity="success"
                        onClick={() => setIsTimeCounterRunning(true)}
                        style={{ margin: '0.5em' }}
                    />
                </div>
            }
        </div>;

    useEffect(
        () => {
            loadPosts();
            setTaskBody(selectedTask.body ?? '');
            setTaskTitle(selectedTask.title);
            setIsBodyEditable(false);
            setIsTitleEditable(false);
        },
        [selectedTask]
    );

    return (
        <div className='task-page'>
            <div
                align='right'
                onClick={() => setSelectedTask(null)}
                style={{ cursor: 'pointer' }}
            >
                <i className="pi pi-times"
                    style={{ fontSize: '1rem' }} />
            </div>
            {divTitle}
            {divBody}
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                T32453253, T43532252, T4343435
            </div>
            {divTimeCounter}
            <h4>
                Subscribers
            </h4>
            John Doe, Dana Popescu, Andy Lewis, James Clarke
            <h4>
                Comments
            </h4>
            {divPosts}
            <div
                style={{ marginTop: '2em', marginBottom: '2em' }}
            >
                <input
                    className='task-comment-new'
                    placeholder='add comment'
                    value={postTitle}
                    onChange={(e) => setPostTitle(e.target.value)}
                    onKeyDown={
                        (e) => {
                            if (e.key == 'Enter') {
                                createPost()
                                setPostTitle('');
                                return;
                            }
                            if (e.key == 'Escape') {
                                setPostTitle('');
                                return;
                            }
                        }
                    }
                />
                <div
                    align='right'
                    style={{ marginTop: '0.5em', cursor: 'pointer' }}
                    onClick={createPost}
                >
                    Post
                </div>
            </div>
        </div>
    );
}

export default TaskPage;